import {Injectable} from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {catchError, Observable, throwError} from 'rxjs';
import {SessionService} from "../state/session.service";
import {Router} from "@angular/router";

interface AppHeaders {
  [name: string]: string | string[];
}

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(private readonly session: SessionService,
              private readonly router: Router) {
  }

  intercept(
    httpRequest: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    var headers: AppHeaders = { };

    if (this.session.authToken) {
      headers = {
        ...headers,
        Authorization: 'Bearer ' + this.session.authToken,
      };
    }

    httpRequest = httpRequest.clone({
      setHeaders: headers,
    });

    return next.handle(httpRequest).pipe(
      catchError((error) => {
        if (error.status === 401 || error.status === 403) {
          this.session.authToken = undefined;
          this.router.navigate(['/login']);
        }
        return throwError(error);
      }),
    );
  }
}
