import {Component, OnDestroy, OnInit} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";
import {CommonModule, DatePipe} from "@angular/common";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {MatButtonModule} from "@angular/material/button";
import {DomSanitizer} from "@angular/platform-browser";
import {SessionService} from "../state/session.service";
import {MatFormField, MatOption, MatSelect} from "@angular/material/select";
import {MatLabel} from "@angular/material/form-field";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatInputModule} from "@angular/material/input";
import {debounceTime, distinctUntilChanged, filter, map, mergeMap, Subject, switchMap, tap} from "rxjs";
import {FormsModule} from "@angular/forms";
import {MatListModule} from "@angular/material/list";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {Client} from "../client-overview/dto/client";

export interface SearchResult {
  name: string;
  description?: string;
  type: string; // 'product' | 'event'
  imageUrl?: string;
  externalId?: string;
}

@Component({
  selector: 'searchbar-component',
  template: `
    <mat-form-field>
      <mat-label>Client</mat-label>
      <mat-select placeholder="Select Client" [(value)]="selectedClient" (selectionChange)="fetchTokenAndInit()">
        <mat-option *ngFor="let client of clients" [value]="client">{{ client.name }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Search</mat-label>
      <input matInput
             (ngModelChange)="searchSubject.next($event)"
             [(ngModel)]="search"
             aria-label="Search">
      <mat-spinner [diameter]="24" [strokeWidth]="4" matIconSuffix *ngIf="loading"></mat-spinner>
    </mat-form-field>
    <mat-list>
      <mat-list-item *ngFor="let entry of result">
        <img matListItemAvatar [src]="entry.imageUrl" *ngIf="entry.imageUrl">
        <div matListItemTitle>
          <span class="name">{{ entry.name }}</span>
          <div class="odds-list" *ngIf="entry.type === 'event'">
            <div class="odds">
              <span>1</span>
              <span>2.2</span>
            </div>
            <div class="odds">
              <span>X</span>
              <span>2</span>
            </div>
            <div class="odds">
              <span>2</span>
              <span>2.4</span>
            </div>
          </div>

          <div class="odds-list" *ngIf="entry.type === 'product'">
            <button mat-flat-button>Play</button>
          </div>
        </div>
        <div matListItemLine *ngIf="entry.description">{{ entry.description }}</div>
      </mat-list-item>
    </mat-list>
  `,
  styles: [`
    :host {
      width: 100%;
      height: 100%;
      display: flex;
      flex-flow: column;
      padding: 8px;
    }

    mat-form-field {
      width: calc(100% - 16px);
      margin-bottom: 8px;
    }

    mat-spinner {
      margin-right: 20px;
    }

    ::ng-deep .mat-mdc-list-item-title {
      display: flex !important;
      align-items: center;

      &:active {
        background-color: rgba(0, 0, 0, 0.1);
      }

      .name {
        flex: 1;
      }

      .odds-list {
        display: flex;
        gap: 8px;

        .odds {
          display: flex;
          flex-flow: column;
          align-items: center;
          justify-content: center;
          padding: 2px;
          border-radius: 2px;
          border: 1px solid rgba(0, 0, 0, 0.2);
          width: 50px;

          span:first-of-type {
            font-size: 0.8em;
          }

          span:last-of-type {
            font-weight: bold;
            color: #1BB14C;
          }
        }
      }
    }
  `],
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatSelect, MatOption, MatFormField, MatLabel, MatInputModule,
    MatListModule, MatAutocompleteModule, FormsModule, MatProgressSpinner]
})
export class SearchbarDialogComponent implements OnInit, OnDestroy {

  search = '';
  selectedResult?: any;
  result: SearchResult[] = [];
  selectedClient?: Client;
  clients: Client[] = [];
  loading = false;
  latestSearchIdx = 0;
  processedSearchIdx = 0;

  searchSubject = new Subject<string>();

  constructor(
    public dialogRef: MatDialogRef<SearchbarDialogComponent>,
    private readonly sanitizer: DomSanitizer,
    private readonly sessionService: SessionService,
    private readonly datePipe: DatePipe,
    private readonly httpClient: HttpClient
  ) {
  }

  ngOnInit(): void {
    this.httpClient.get<Client[]>(environment.API_URL + '/api/client/all')
      .subscribe(clients => {
        this.selectedClient = clients[0];
        this.clients = clients;
        this.fetchTokenAndInit();
      });

    this.searchSubject.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      filter(term => term.length > 2),
      tap(() => this.loading = true),
      mergeMap(term => this.fetchData(term).pipe(map(data => ({ searchId: ++this.latestSearchIdx, data }))))
    ).subscribe(
      (res: {searchId: number, data: { events: any[], products: any[] }}) => {
        if (res.searchId < this.processedSearchIdx) {
          console.log('Skipping outdated search result', res.searchId, this.processedSearchIdx);
          return;
        }
        this.processedSearchIdx = res.searchId;
        this.result = [];
        if (res.data.events.length > 0) {
          this.result.push(...res.data.events.map(e => ({
            name: e.name,
            description: this.datePipe.transform(e.startTs, 'short') || '',
            imageUrl: e.imageUrl,
            type: 'event'
          })));
        }
        if (res.data.products.length > 0) {
          this.result.push(...res.data.products.map(e => ({
            name: e.name,
            // description: e.description,
            imageUrl: e.imageUrl,
            type: 'product',
            externalId: e.externalId
          })));
        }
        this.loading = false;
      },
      () => this.loading = false
    );
  }

  fetchTokenAndInit() {
    console.log('fetchTokenAndInitIframe', this.selectedClient?.name);
    this.httpClient.post<{ token: string }>(environment.API_URL + '/api/client/activate/user', {
      externalId: this.sessionService.user?.externalId,
      test: true
    }, {
      headers: {'X-Client-Secret': this.selectedClient!.apiToken}
    }).subscribe(res => {
      this.search = '';
      this.selectedResult = undefined;
      this.result = [];
    });
  }

  private fetchData(text: string) {
    let formData = new FormData();
    formData.append("prompt", text.trim());
    let options: any = {
      body: formData,
      observe: 'response',
      responseType: 'json',
      headers: new HttpHeaders({
        Accept: 'application/json'
      })
    };
    this.loading = true;
    return this.httpClient.request<any>('post', environment.API_URL + '/api/prompt', options)
      .pipe(
        map((response) => (response as any).body as { uuid: string }),
        switchMap((prompt) => this.httpClient.get<any>(environment.API_URL + '/api/prompt/' + prompt.uuid + '/completion?status=EXTENDED_RESULT'))
      );
  }

  ngOnDestroy(): void {
  }
}
