import {ApplicationConfig} from '@angular/core';
import {provideRouter, withComponentInputBinding} from '@angular/router';

import {routes} from './app.routes';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi
} from "@angular/common/http";
import {AuthInterceptor} from "./auth/auth.interceptor";
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {DatePipe, DecimalPipe} from "@angular/common";

export const appConfig: ApplicationConfig = {
  providers: [
    DatePipe,
    DecimalPipe,
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(routes, withComponentInputBinding()), {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    }, provideAnimationsAsync()]
};
