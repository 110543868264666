import {Routes} from '@angular/router';
import {loggedInAuthGuard} from './auth/is-logged-in.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'prompt-list',
    pathMatch: 'full',
  },
  {
    path: 'prompt-list',
    loadComponent: () =>
      import('./prompt-list/prompt-list.component').then(
        (m) => m.PromptListComponent
      ),
    canActivate: [loggedInAuthGuard],
  },
  {
    path: 'prompt-list/:id',
    loadComponent: () =>
      import('./prompt-list/prompt-list.component').then(
        (m) => m.PromptListComponent
      ),
    canActivate: [loggedInAuthGuard],
  },
  {
    path: 'system-prompt',
    loadChildren: () =>
      import('./system-prompt/system-prompt.module').then(
        (m) => m.SystemPromptModule
      ),
    canActivate: [loggedInAuthGuard],
  },
  {
    path: 'betting-market-list',
    loadChildren: () =>
      import('./betting-market-list/betting-market-list.module').then(
        (m) => m.BettingMarketListModule
      ),
    canActivate: [loggedInAuthGuard],
  },
  {
    path: 'user-list',
    loadComponent: () =>
      import('./user-list/user-list.page').then((m) => m.UserListPage),
    canActivate: [loggedInAuthGuard],
  },
  {
    path: 'product-overview',
    loadChildren: () =>
      import('./product-overview/product-overview.module').then(
        (m) => m.ProductOverviewModule
      ),
    canActivate: [loggedInAuthGuard],
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./login/login.component').then((m) => m.LoginComponent),
  },
];
