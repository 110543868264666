<mat-drawer-container class="example-container">
  <mat-drawer class="example-sidenav" mode="side" [opened]="!!session.authToken">
    <button mat-button [routerLink]="['prompt-list']">User Prompts</button>
    <button mat-button [routerLink]="['system-prompt']">System Config</button>
    <button mat-button [routerLink]="['betting-market-list']">Betting Markets</button>
    <button mat-button [routerLink]="['user-list']">User List</button>
    <button mat-button [routerLink]="['product-overview']">Products</button>
    <button mat-button (click)="showWebview()">Webview</button>
    <button mat-button (click)="showSearchbar()">Searchbar</button>
    <button class="logout-button" mat-button (click)="logout()">Logout</button>
  </mat-drawer>
  <router-outlet />
</mat-drawer-container>
