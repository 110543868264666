<mat-drawer-container class="example-container">
  <mat-drawer class="example-sidenav" mode="side" [opened]="!!session.authToken">
    <button mat-button [routerLink]="['prompt-list']" *ngIf="session.isManager()">Prompts</button>
    <button mat-button (click)="showPromptExport()" *ngIf="session.isManager()" >Export</button>
    <button mat-button [routerLink]="['system-prompt']" *ngIf="session.isAdmin()">System Config</button>
    <button mat-button [routerLink]="['betting-market-list']" *ngIf="session.isAdmin()">Betting Markets</button>
    <button mat-button [routerLink]="['combo-markets']" *ngIf="session.isAdmin()">Combo Markets</button>
    <button mat-button [routerLink]="['user-list']" *ngIf="session.isAdmin()">User List</button>
    <button mat-button [routerLink]="['league-list']" *ngIf="session.isManager()">League List</button>
    <button mat-button [routerLink]="['club-list']" *ngIf="session.isAdmin()">Club List</button>
    <button mat-button [routerLink]="['player-list']" *ngIf="session.isAdmin()">Player List</button>
    <button mat-button [routerLink]="['match-list']" *ngIf="session.isAdmin()">Match List</button>
    <button mat-button [routerLink]="['product-overview']" *ngIf="session.isAdmin()">Products</button>
    <button mat-button [routerLink]="['client-overview']" *ngIf="session.isAdmin()">Clients</button>
    <button mat-button (click)="showWebview()" *ngIf="session.isAdmin()">Webview</button>
    <button mat-button (click)="showSearchbar()" *ngIf="session.isAdmin()">Searchbar</button>
    <button class="logout-button" mat-button (click)="logout()">Logout</button>
  </mat-drawer>
  <router-outlet />
</mat-drawer-container>
