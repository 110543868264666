<h1 mat-dialog-title>Export</h1>
<div mat-dialog-content>
  <form class="prompt-form" [formGroup]="formGroup">
    <mat-form-field appearance="fill" class="form-field">
      <mat-label>Range</mat-label>
      <mat-select formControlName="timeframe">
        <mat-option *ngFor="let timeframe of timeframes" [value]="timeframe">
          {{ timeframe.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-button color="primary" (click)="export()" [disabled]="!formGroup.valid || submitted">Export</button>
</div>
