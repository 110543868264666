import {Routes} from '@angular/router';
import {loggedInAuthGuard} from './auth/is-logged-in.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'prompt-list',
    pathMatch: 'full',
  },
  {
    path: 'prompt-list',
    loadComponent: () =>
      import('./prompt-list/prompt-list.component').then(
        (m) => m.PromptListComponent
      ),
    canActivate: [loggedInAuthGuard],
  },
  {
    path: 'prompt-list/:id',
    loadComponent: () =>
      import('./prompt-list/prompt-list.component').then(
        (m) => m.PromptListComponent
      ),
    canActivate: [loggedInAuthGuard],
  },
  {
    path: 'system-prompt',
    loadChildren: () =>
      import('./system-prompt/system-prompt.module').then(
        (m) => m.SystemPromptModule
      ),
    canActivate: [loggedInAuthGuard],
  },
  {
    path: 'betting-market-list',
    loadChildren: () =>
      import('./betting-market-type-list/betting-market-type-list.module').then(
        (m) => m.BettingMarketTypeListModule
      ),
    canActivate: [loggedInAuthGuard],
  },
  {
    path: 'combo-markets',
    loadChildren: () =>
      import('./combo-market/combo-market.module').then(
        (m) => m.ComboMarketModule
      ),
    canActivate: [loggedInAuthGuard],
  },
  {
    path: 'user-list',
    loadComponent: () =>
      import('./user-list/user-list.page').then((m) => m.UserListPage),
    canActivate: [loggedInAuthGuard],
  },
  {
    path: 'league-list',
    loadComponent: () =>
      import('./league-list/league-list.page').then((m) => m.LeagueListPage),
    canActivate: [loggedInAuthGuard],
  },
  {
    path: 'club-list',
    loadComponent: () =>
      import('./club-list/club-list.page').then((m) => m.ClubListPage),
    canActivate: [loggedInAuthGuard],
  },
  {
    path: 'club-list/league/:leagueId',
    loadComponent: () =>
      import('./club-list/club-list.page').then((m) => m.ClubListPage),
    canActivate: [loggedInAuthGuard],
  },
  {
    path: 'player-list',
    loadComponent: () =>
      import('./player-list/player-list.page').then((m) => m.PlayerListPage),
    canActivate: [loggedInAuthGuard],
  },
  {
    path: 'player-list/club/:clubId',
    loadComponent: () =>
      import('./player-list/player-list.page').then((m) => m.PlayerListPage),
    canActivate: [loggedInAuthGuard],
  },
  {
    path: 'match-list',
    loadComponent: () =>
      import('./match-list/match-list-page.component').then((m) => m.MatchListPage),
    canActivate: [loggedInAuthGuard],
  },
  {
    path: 'match-list/club/:clubId',
    loadComponent: () =>
      import('./match-list/match-list-page.component').then((m) => m.MatchListPage),
    canActivate: [loggedInAuthGuard],
  },
  {
    path: 'match-list/league/:leagueId',
    loadComponent: () =>
      import('./match-list/match-list-page.component').then((m) => m.MatchListPage),
    canActivate: [loggedInAuthGuard],
  },
  {
    path: 'match-list/player/:playerId',
    loadComponent: () =>
      import('./match-list/match-list-page.component').then((m) => m.MatchListPage),
    canActivate: [loggedInAuthGuard],
  },
  {
    path: 'product-overview',
    loadChildren: () =>
      import('./product-overview/product-overview.module').then(
        (m) => m.ProductOverviewModule
      ),
    canActivate: [loggedInAuthGuard],
  },
  {
    path: 'client-overview',
    loadChildren: () =>
      import('./client-overview/client-overview.module').then(
        (m) => m.ClientOverviewModule
      ),
    canActivate: [loggedInAuthGuard],
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./login/login.component').then((m) => m.LoginComponent),
  },
];
