import {Component, OnDestroy, OnInit} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";
import {CommonModule} from "@angular/common";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {MatButtonModule} from "@angular/material/button";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {SessionService} from "../state/session.service";
import {MatFormField, MatOption, MatSelect} from "@angular/material/select";
import {Client} from "../user-list/types/user.model";
import {MatLabel} from "@angular/material/form-field";

@Component({
  selector: 'webview-component',
  template: `
    <mat-form-field>
      <mat-label>Client</mat-label>
      <mat-select placeholder="Select Client" [(value)]="selectedClient" (selectionChange)="fetchTokenAndInitIframe()">
        <mat-option *ngFor="let client of clients" [value]="client">{{client.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <iframe allow="microphone" [src]="url" *ngIf="url">
    </iframe>
  `,
  styles: [`
    :host {
      width: 100%;
      height: 100%;
      display: flex;
      flex-flow: column;
      padding: 8px;
    }

    mat-form-field {
      width: calc(100% - 16px);
      margin-bottom: 8px;
    }

    iframe {
      width: calc(100% - 16px);
      flex: 1;
      border: none;
    }
  `],
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatSelect, MatOption, MatFormField, MatLabel]
})
export class WebviewDialogComponent implements OnInit, OnDestroy {

  url?: SafeUrl;
  selectedClient?: Client;
  clients: Client[] = [];
  private boundHandleMessage = this.handleMessage.bind(this);

  constructor(
    public dialogRef: MatDialogRef<WebviewDialogComponent>,
    private readonly sanitizer: DomSanitizer,
    private readonly sessionService: SessionService,
    private readonly httpClient: HttpClient
  ) {}

  ngOnInit(): void {
    this.httpClient.get<Client[]>(environment.API_URL + '/api/client/all').subscribe(clients => {
      this.selectedClient = clients[0];
      this.clients = clients;
      this.url = undefined;
      this.fetchTokenAndInitIframe();
    });

    window.addEventListener('message', this.boundHandleMessage, false);
  }

  fetchTokenAndInitIframe() {
    console.log('fetchTokenAndInitIframe', this.selectedClient?.name);
    this.httpClient.post<{ token: string }>(environment.API_URL + '/api/client/activate/user', {
      externalId: this.sessionService.user?.externalId,
      test: true
    }, {
      headers: {'X-Client-Secret': this.selectedClient!.apiToken}
    }).subscribe(res => {
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(`${environment.WEBVIEW_URL}?token=${res.token}`);
    });
  }

  ngOnDestroy(): void {
    window.removeEventListener('message', this.boundHandleMessage, false);
  }

  handleMessage(event: any) {
    console.log('received message', event);
    if (event.data.type === 'close') {
      this.dialogRef.close();
    }
  }
}
