import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {Component} from "@angular/core";
import {MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {PromptService} from "../prompt.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatButtonModule} from "@angular/material/button";
import {CommonModule} from "@angular/common";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {SessionService} from "../../state/session.service";

@Component({
  standalone: true,
  selector: 'export-prompt-list-dialog',
  templateUrl: 'export-prompt-list-dialog.component.html',
  styleUrls: ['./export-prompt-list-dialog.component.scss'],
  imports: [CommonModule, MatFormFieldModule, MatInputModule, MatButtonModule, ReactiveFormsModule, MatIconModule,
    MatDialogModule, MatOption, MatSelect],
})
export class ExportPromptListDialogComponent {
  formGroup!: FormGroup;
  submitted = false;
  timeframes: { label: string, from: Date, to: Date }[] = [];

  constructor(public dialogRef: MatDialogRef<ExportPromptListDialogComponent>,
              private readonly formBuilder: FormBuilder,
              private readonly promptService: PromptService,
              private readonly session: SessionService,
              private readonly snack: MatSnackBar) {

    this.initTimeframes();
    this.formGroup = this.formBuilder.group({
      timeframe: [this.timeframes[0], Validators.required],
    });
  }

  onCancel(): void {
    this.dialogRef.close(null);
  }

  export(): void {
    if (!this.formGroup.value.timeframe) {
      return;
    }

    this.snack.open("Exporting...", "Close");
    this.promptService.export(this.formGroup.value.timeframe.from, this.formGroup.value.timeframe.to).subscribe({
      next: () => {
        this.dialogRef.close();
        this.snack.open("Export finished", "Close");
      },
      error: (() => {
        this.snack.open("Export failed!", "Close");
        this.submitted = false;
      })
    });
  }

  private initTimeframes() {
    // Today
    let todayStart = new Date();
    todayStart.setHours(0, 0, 0, 0);
    let todayEnd = new Date();
    todayEnd.setHours(23, 59, 59, 999);
    this.timeframes.push({label: 'Today', from: todayStart, to: todayEnd});

    // Yesterday
    let yesterdayStart = new Date(todayStart);
    yesterdayStart.setDate(yesterdayStart.getDate() - 1);
    let yesterdayEnd = new Date(todayEnd);
    yesterdayEnd.setDate(yesterdayEnd.getDate() - 1);
    this.timeframes.push({label: 'Yesterday', from: yesterdayStart, to: yesterdayEnd});

    // This Week (Mon–Sun)
    let currentDate = new Date();
    let thisWeekStart = new Date(currentDate);
    let day = thisWeekStart.getDay();
    let diff = day === 0 ? 6 : day - 1;
    thisWeekStart.setDate(thisWeekStart.getDate() - diff);
    thisWeekStart.setHours(0, 0, 0, 0);

    let thisWeekEnd = new Date(thisWeekStart);
    thisWeekEnd.setDate(thisWeekEnd.getDate() + 6);
    thisWeekEnd.setHours(23, 59, 59, 999);
    this.timeframes.push({label: 'This Week', from: thisWeekStart, to: thisWeekEnd});

    // Last Week (Mon–Sun)
    let lastWeekStart = new Date(thisWeekStart);
    lastWeekStart.setDate(lastWeekStart.getDate() - 7);
    let lastWeekEnd = new Date(lastWeekStart);
    lastWeekEnd.setDate(lastWeekEnd.getDate() + 6);
    lastWeekStart.setHours(0, 0, 0, 0);
    lastWeekEnd.setHours(23, 59, 59, 999);
    this.timeframes.push({label: 'Last Week', from: lastWeekStart, to: lastWeekEnd});

    // This Month (1st–last day)
    let thisMonthStart = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1, 0, 0, 0, 0);
    let thisMonthEnd = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0, 23, 59, 59, 999);
    this.timeframes.push({label: 'This Month', from: thisMonthStart, to: thisMonthEnd});

    // Last Month (1st–last day)
    let lastMonthStart = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1, 0, 0, 0, 0);
    let lastMonthEnd = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0, 23, 59, 59, 999);
    this.timeframes.push({label: 'Last Month', from: lastMonthStart, to: lastMonthEnd});

    if (this.session.isAdmin()) {
      // This Year (Jan 1–Dec 31)
      const thisYearStart = new Date(currentDate.getFullYear(), 0, 1, 0, 0, 0, 0);
      const thisYearEnd = new Date(currentDate.getFullYear(), 11, 31, 23, 59, 59, 999);
      this.timeframes.push({label: 'This Year', from: thisYearStart, to: thisYearEnd});

      // Last Year (Jan 1–Dec 31)
      const lastYearStart = new Date(currentDate.getFullYear() - 1, 0, 1, 0, 0, 0, 0);
      const lastYearEnd = new Date(currentDate.getFullYear() - 1, 11, 31, 23, 59, 59, 999);
      this.timeframes.push({label: 'Last Year', from: lastYearStart, to: lastYearEnd});

      // All Time (from 1970-01-01 up to today)
      const allTimeStart = new Date(1970, 0, 1, 0, 0, 0, 0);
      const allTimeEnd = new Date(currentDate);
      allTimeEnd.setHours(23, 59, 59, 999);
      this.timeframes.push({label: 'All Time', from: allTimeStart, to: allTimeEnd});
    }
  }
}
