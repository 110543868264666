import {Component, OnInit} from '@angular/core';
import {Router, RouterLink, RouterOutlet} from '@angular/router';
import {AuthService} from "./auth/auth-service";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatButtonModule} from "@angular/material/button";
import {CommonModule} from "@angular/common";
import {SessionService} from "./state/session.service";
import {MatDialog} from "@angular/material/dialog";
import {WebviewDialogComponent} from "./webview/webview-dialog.component";
import {SearchbarDialogComponent} from "./searchbar/searchbar-dialog.component";
import {ExportPromptListDialogComponent} from "./prompt-list/export/export-prompt-list-dialog.component";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, MatSidenavModule, MatButtonModule, CommonModule, RouterLink],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  constructor(private authService: AuthService,
              private router: Router,
              private dialog: MatDialog,
              public session: SessionService) {
  }

  showPromptExport() {
    this.dialog.open(ExportPromptListDialogComponent);
  }

  ngOnInit() {
    this.authService.refreshToken();
  }

  logout() {
    this.session.setAuthToken(undefined);
    this.router.navigate(['']);
  }

  showWebview() {
    this.dialog.open(WebviewDialogComponent, {width: '90%', maxWidth: '600px', height: '90%', maxHeight: '1000px'});
  }

  showSearchbar() {
    this.dialog.open(SearchbarDialogComponent, {width: '90%', maxWidth: '600px', height: '90%', maxHeight: '1000px'});
  }
}
